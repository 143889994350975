import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started",
  "description": "Svrf's API makes it simple for you to add immersive experiences to your app. Let your users search for AR face filters, 360 photos, or 360 videos."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-is-the-svrf-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-the-svrf-api",
        "aria-label": "what is the svrf api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is the Svrf API?`}</h2>
    <p>{`The Svrf API allows you to supercharge your project or app with the first and largest search engine for immersive experiences. We make it simple for any developer to incorporate highly immersive experiences with all kinds of applications: augmented reality, virtual reality, mixed reality, mobile, and web.`}</p>
    <p>{`The Svrf API is RESTful and communicates using `}<a parentName="p" {...{
        "href": "http://www.json.org/"
      }}>{`JSON`}</a>{`. We support `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cross-origin_resource_sharing"
      }}>{`CORS`}</a>{`, allowing you to access immersive experiences indexed by Svrf on your own web domains. We also provide a variety of resolutions, projections, and file formats to support most modern clients.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "https://www.svrf.com/terms"
      }}>{`Terms of Service`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.svrf.com/privacy"
      }}>{`Privacy Policy`}</a>{` for restrictions on using the Svrf API, libraries, and SDKs.`}</p>
    <p>{`To generate a free Svrf API Key, create an account on `}<a parentName="p" {...{
        "href": "https://www.svrf.com"
      }}>{`www.svrf.com`}</a>{` and scroll down to the `}<em parentName="p">{`Apps`}</em>{` section of the `}<a parentName="p" {...{
        "href": "https://www.svrf.com/user/settings"
      }}>{`User Settings`}</a>{` page.`}</p>
    <h2 {...{
      "id": "shortcuts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#shortcuts",
        "aria-label": "shortcuts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shortcuts`}</h2>
    <div className="two colored cards">
  <a href="/quick-start-guides/ios">
    <div>
      <h2>iOS Apps</h2>
      <p>An overview of the tools and the essential concepts surrounding the iOS SDK.</p>
    </div>
  </a>
  <a href="/quick-start-guides/unity" target="_blank">
    <div>
      <h2>Unity Apps</h2>
      <p>A guide and essential concepts for integrating Svrf into your Unity project.</p>
    </div>
  </a>
  <a href="/quick-start-guides/web" target="_blank">
    <div>
      <h2>Web Apps</h2>
      <p>A quickstart guide and essential concepts for integrating Svrf into your web app.</p>
    </div>
  </a>
  <a href="/api-reference/authenticate">
    <div>
      <h2>API Endpoints</h2>
      <p>A complete API reference for each endpoint's request and response features.</p>
    </div>
  </a>
  <a href="/recipes/face-filter-iphone-app">
    <div>
      <h2>Recipes</h2>
      <p>Several code examples to get you started, and inspire your creations.</p>
    </div>
  </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      